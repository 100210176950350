* {
  padding: 0%;
  margin: 0%;
  height: auto;
  box-sizing: border-box;

}
.fondo {
  background-color: #fff;
  background-image: url("https://backend-unificado.vertebra-soluciones.com/downloads/avatars/fondo.png");
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.card-bacground {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 100% 100%;
  background: linear-gradient(45deg, #fff, #28d872);
  right: 0%;
  opacity: 0.2;
}

.forms-containers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.title-form {
  font-size: 1.5rem;
  color: #444;
}

.singIn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1rf;
}

form {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.singIn {
  z-index: 2;
}
