* {
  margin: 0;
  padding: 0;
}

.log-in {
  display: flex;
  background-size: cover;
}

.margin {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.contentcontent {
  display: flex;
  align-items: center;
}

.margin img {
  width: 150%;
  top: 25%;
  padding: 60px 20px;
  transform: translate(-25%);
  @media screen and (min-width: 481px) and (max-width: 1400px) {
    transform: translate(-500%);
  }
}

.lef {
  position: absolute;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: #28ae60;
  border-bottom-right-radius: 16% 70%;
  border-top-right-radius: 16% 70%;

  @media screen and (min-width: 481px) and (max-width: 1400px) {
    width: 100%;
    transform: all 50s ease;
    border-bottom-right-radius: 0% 0%;
    border-top-right-radius: 0% 0%;
  }
}

.right {
  position: absolute;
  right: 20%;
  top: 25%;
  left: 55%;
  transform: translate(25%);

  @media screen and (min-width: 481px) and (max-width: 1400px) {
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
  }
}

.card {
  position: absolute;
  border: 0.1px;
  width: 60vw;
  height: 70vh;
  border-radius: 10px;
  left: -5%;
  box-shadow: 3px 3px 6px grey;
  -moz-box-shadow: 3px 3px 6px grey;
  -webkit-box-shadow: 3px 3px 6px grey;

  @media screen and (min-width: 481px) and (max-width: 1400px) {
    width: 100%;
  }
}

.card .ant-card-head-title img {
  padding: 10px;
  width: 100%;

  @media screen and (min-width: 481px) and (max-width: 1400px) {
    width: 50%;
    padding: auto;
    transform: translate(50%);
  }
}

.button {
  border: 1px solid transparent;
  border-radius: 30px;
  width: 100%;
  box-shadow: var(box-shadow-base);
  margin-top: 5%;
  @media screen and (min-width: 481px) and (max-width: 1400px) {
    margin-top: 0%;
  }
}

.input {
  margin-top: 5%;
  border: 1px solid white;
  border-radius: 30px;
  height: 30px;

  @media screen and (min-width: 481px) and (max-width: 1400px) {
    margin-top: 2%;
  }
}
.ant-input-suffix svg {
  color: white;
}

.tipography {
  @media screen and (min-width: 481px) and (max-width: 1400px) {
    width: 50%;
    padding: auto;
    transform: translate(55%);
  }
}
